<template>
  <div :class="$style.container">
    <br />
    <div :class="$style.topContentWrapper">
      <adw-button
        small
        :link="
          getRoute({
            route: $options.ADDSELLERS_ADMIN_ROUTES.ORDERS.ADDSELLERS.UPDATE,
            params: { id: $route.params.id },
          })
        "
      >
        Редактирование
      </adw-button>
      <div v-if="orderProducts[0]?.pricePolicy">
        {{ `Ценовая политика: ${orderProducts[0].pricePolicy}` }}
      </div>
    </div>
    <adw-button
      v-if="order.createdManagerId && isOwner"
      :class="$style.createManagerButton"
      small
      :link="
        getRoute({
          route: $options.MANAGERS_ROUTES.UPDATE,
          params: { id: order.createdManager.id },
        })
      "
    >
      Создан менеджером {{ order.createdManager.name }}
    </adw-button>
    <OrderBlock
      :id="order.id"
      :status="order.status"
      :date="order.createdAt"
      :name="order.name"
      :company="clientRequesite.name"
      :recipient="recipientName"
      :delivery-date="order.deliveryAt"
      :warehouse="order.warehouse"
      :price="order.price"
      :delivery-type="order.deliveryType"
      :delivery-price="order.deliveryPrice"
    />
    <OrderCard
      :price="order.price"
      :order-products="orderProducts"
      :payment="payment"
      :delivery-price="order.deliveryPrice"
      :warehouse="order.warehouse"
      :discount="order.discountSize"
      :bonusWriteOff="bonusTransactionWriteOffValue"
    />
    <div :class="$style.cards">
      <ClientCard
        :id="clientRequesite.id"
        :class="$style.clientCard"
        title="Реквизиты"
        :name="clientRequesite.name"
        :legal-address="clientRequesite.legalAddress"
        :unp="clientRequesite.unp"
        :ogrn="clientRequesite.ogrn"
        :kpp="clientRequesite.kpp"
        :payment-account="clientRequesite.paymentAccount"
        :bank-name="clientRequesite.bankName"
        :cor-account="clientRequesite.corAccount"
        :bik="clientRequesite.bik"
        :phone="clientRequesite.phone"
        :email="clientRequesite.email"
      />
      <div :class="$style.cardsBlock">
        <div v-if="showDeliveryType">
          <DeliveryCard
            :id="deliveryAddress.id"
            :class="$style.deliveryCard"
            title="Доставка"
            :address="address"
            :comment="order.comment"
          />
        </div>
        <div v-else>
          <div :class="$style.selfDelivery">
            <h2 :class="$style.title">Самовывоз</h2>
            <span>Адрес</span>
            <p>{{ selfDeliveryCard.address }}</p>
            <span>Время работы</span>
            <p>{{ selfDeliveryCard.time }}</p>
          </div>
        </div>
        <div :class="$style.recipientCard">
          <h2 :class="$style.title">Получатель</h2>
          <p>
            {{ recipientName }}
          </p>
          <span>Email</span>
          <p>{{ recipient.email }}</p>
          <span>Телефон</span>
          <p>{{ recipient.phone }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import delivery from '@/delivery'
import {
  ADDSELLERS_ADMIN_ROUTES,
  MANAGERS_ROUTES,
  getRoute,
} from '@/constants/routing'
import { mapGetters } from 'vuex'

import OrderCard from '@/components/atoms/cards/SellersOrderCard.vue'
import OrderBlock from '@/components/moleculs/SellersOrderProductsBlock.vue'
import ClientCard from '@/components/atoms/cards/ClientRequisitesCard.vue'
import DeliveryCard from '@/components/atoms/cards/DeliveryInfoCard.vue'

export default {
  ADDSELLERS_ADMIN_ROUTES,
  MANAGERS_ROUTES,
  components: { OrderCard, OrderBlock, ClientCard, DeliveryCard },
  middleware: ['isAuth', 'isVerified'],
  data() {
    return {
      bonusTransactionWriteOffValue: 0,
      order: { name: '' },
      orderProducts: [],
      clientRequesite: {},
      deliveryAddress: {},
      recipient: {},
      selfDeliveryCard: {
        address: 'Москва, Волгоградский пр., 32, к. 8',
        time: 'пн-пт, 10:00 - 21:00',
      },
      paymentType: {
        CASH: 'Оплата наличными',
        BILL: 'Оплата на расчетный счет',
      },
    }
  },

  computed: {
    ...mapGetters({
      isOwner: 'isOwner',
    }),
    recipientName() {
      return (
        this.recipient.lastName +
        ' ' +
        this.recipient.firstName +
        ' ' +
        this.recipient.middleName
      )
    },
    address() {
      return [
        `город ${this.deliveryAddress.city}`,
        this.deliveryAddress.street,
        this.deliveryAddress.office && `офис ${this.deliveryAddress.office}`,
      ].join(', ')
    },
    showSum() {
      return this.products.reduce(
        (sum, product) => sum + product.price * product.amount,
        0,
      )
    },
    showDeliveryType() {
      return this.order.deliveryType !== 'SELFDELIVERY'
    },
    showDeliveryPrice() {
      return this.order.deliveryPrice
    },
    payment() {
      return this.paymentType[this.order.payment] || ''
    },
  },
  mounted() {
    this.$nextTick(async () => {
      await this.getCard()
    })
  },
  methods: {
    async getCard() {
      const result = await delivery.AddwineCore.OrdersActions.getById(
        this.$route.params.id,
      )

      if (result.error) {
        this.error = result.error
        return
      }

      this.order = result.value.order
      this.clientRequesite = this.order.clientRequesite
      this.deliveryAddress = this.order.deliveryAddress
      this.recipient = this.order.recipient
      this.orderProducts = result.value.orderProducts

      if (this.order.bonusTransactionWriteOff) {
        this.bonusTransactionWriteOffValue = Math.abs(
          this.order.bonusTransactionWriteOff.value,
        )
      }
    },
    getRoute(route, params) {
      return getRoute(route, params)
    },
  },
}
</script>

<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/breakpoints.scss';

.container {
  @include container;
  & > div {
    margin-top: 1.5rem;
  }
  .topContentWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
  }

  .cards {
    display: flex;
    margin-bottom: 1.5rem;
    @media (max-width: 1024px) {
      flex-direction: column;
    }
    .clientCard {
      margin-right: 1.5rem;
      margin-bottom: 10rem;
      @media (max-width: 1024px) {
        margin-bottom: 1.5rem;
        margin-right: 0;
      }
      & > div:nth-child(2) {
        display: none;
      }
      & > div:first-child > h2:nth-child(2) {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.375rem;
      }
    }
    .cardsBlock {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      .recipientCard,
      .selfDelivery {
        @include card;
        .title {
          @include H200;
          margin-bottom: 1.5rem;
        }
        p:not(:last-child) {
          margin-bottom: 1.5rem;
        }
        span {
          font-size: 0.625rem;
          line-height: 0.875rem;
          color: $gray;
          margin-bottom: 0.25rem;
        }
      }
      .selfDelivery {
        margin-bottom: 1.5rem;
      }
      .deliveryCard {
        min-height: 0;
        margin-bottom: 1.5rem;
        & > div:nth-child(2) {
          display: none;
        }
        & > div:first-child > div > p:first-child {
          color: $gray;
        }
      }
    }
  }
  .button {
    margin-bottom: 6rem;
  }
}
</style>
