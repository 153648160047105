<template>
  <div :class="$style.container">
    <div :class="[$style.orderStatus, $style[changeCase(status)]]">
      {{ showStatus(status) }}
    </div>

    <div :class="$style.orderInfo">
      <div>
        <p :class="$style.orderDate">Заказ от {{ formatDate(date) }}</p>
        <p :class="$style.orderId">{{ name }}</p>
      </div>

      <div :class="$style.recipient">
        <p>для: {{ company }}</p>
        <p>получатель: {{ recipient }}</p>
      </div>

      <div :class="$style.deliveryDate">
        <p v-if="deliveryType === 'DELIVERY'">
          доставка: {{ formatDate(deliveryDate) }}
        </p>
        <p v-if="deliveryType === 'SELFDELIVERY'">самовывоз</p>
      </div>

      <div :class="$style.cost">
        <div>
          <p v-if="warehouse === 'IE'">Склад ИП:</p>
          <p v-if="warehouse === 'LLC'">Склад ООО:</p>
          <p v-if="warehouse === 'RW'">Удаленный склад:</p>
          <p v-if="warehouse === 'RWC'">Удаленный склад (К):</p>
          <p v-if="deliveryType !== 'SELFDELIVERY'">Доставка:</p>
        </div>

        <div :class="$style.sum">
          <p>{{ price }} &#8381;</p>
          <p v-if="deliveryType !== 'SELFDELIVERY'">
            {{ deliveryPrice }} &#8381;
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/helpers'
import { ORDER_STATE_TYPES_SELLERS_DISPLAY } from '@/constants/orderStatuses'
export default {
  ORDER_STATE_TYPES_SELLERS_DISPLAY,
  props: {
    id: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    company: {
      type: String,
      default: '',
    },
    recipient: {
      type: String,
      default: '',
    },
    deliveryDate: {
      type: String,
      default: '',
    },
    warehouse: {
      type: String,
      default: '',
    },
    price: {
      type: Number,
      default: null,
    },
    deliveryType: {
      type: String,
      default: '',
    },
    deliveryPrice: {
      type: Number,
      default: null,
    },
  },
  methods: {
    formatDate(date) {
      return formatDate(date)
    },
    changeCase(value) {
      return value ? value.toLowerCase() : 'status'
    },
    showStatus(value) {
      return (
        ORDER_STATE_TYPES_SELLERS_DISPLAY[value] ||
        ORDER_STATE_TYPES_SELLERS_DISPLAY.PROCESSING
      )
    },
  },
}
</script>

<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/breakpoints.scss';

.container {
  @include card;
  position: relative;
  p {
    white-space: nowrap;
  }
  .orderStatus {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0.25rem 1rem;
    border-radius: 0.125rem;
    font-size: 0.75rem;
    line-height: 1rem;
    color: $white;
    font-weight: 700;
    @include mobile {
      font-size: 0.6rem;
      padding: 0.2rem 0.5rem;
    }
    &.skeleton {
      padding: 0;
    }
  }
  .processing {
    background-color: $yellow;
    color: $blue;
  }
  .ordered {
    background-color: $light-gold;
  }
  .confirmed {
    background-color: $light-azure;
  }
  .assembling {
    background-color: $azure;
  }
  .assembled {
    background-color: $violet;
  }
  .selfdelivery {
    background-color: $extra-light-pink;
  }
  .delivery {
    background-color: $light-pink;
  }
  .region_delivery {
    background-color: $pink;
  }
  .success {
    background-color: $canary;
    color: $blue;
  }
  .failed {
    background-color: $iron;
  }
  .defaultstatus {
    background-color: $yellow;
  }
  .orderInfo {
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr 1.2fr;
    grid-column-gap: 2rem;
    @include custom(900) {
      grid-template-columns: 1fr 1.5fr 1fr 1.5fr;
      grid-column-gap: 1rem;
      margin-top: 1.5rem;
      font-size: 0.9rem;
    }
    @include tablet {
      grid-template-columns: 1fr;
      font-size: 0.75rem;
    }
    .orderDate {
      @include H200;
      @include tablet {
        font-size: 1rem;
      }
      @include mobile {
        margin-bottom: 1rem;
      }
    }
    .orderId {
      color: $cyan;
    }
    .deliveryDate {
      display: flex;
      justify-content: flex-start;
    }
    .cost {
      display: flex;
      justify-content: flex-end;
      @include mobile {
        justify-content: start;
      }
      div {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
      .sum {
        margin-left: 1.5rem;
        font-weight: 600;
      }
    }
    .recipient > p {
      white-space: normal;
    }
  }
}
</style>
