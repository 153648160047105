<template>
  <div :class="$style.container">
    <div :class="$style.main">
      <h2 v-if="title" :class="$style.title">{{ title }}</h2>
      <Select
        v-if="selectTypes.length"
        :class="$style.select"
        :options="selectTypes"
        :error="selectError"
        :value="selected"
        placeholder="Выберите юридическое лицо"
        @change="$emit('update:selected', $event)"
      />

      <h2 v-if="!selectTypes.length" :class="$style.name">{{ name }}</h2>

      <div v-if="selected || !selectTypes.length" :class="$style.card">
        <div :class="$style.address">
          <p :class="$style.subtitle">юр. адрес</p>
          <p :class="$style.info">{{ legalAddress }}</p>
        </div>
        <div>
          <p :class="$style.subtitle">ИНН</p>
          <p :class="$style.info">{{ unp }}</p>
        </div>
        <div>
          <p :class="$style.subtitle">ОГРН/ОГРНИП</p>
          <p :class="$style.info">{{ ogrn }}</p>
        </div>
        <div>
          <p :class="$style.subtitle">КПП</p>
          <p :class="$style.info">{{ kpp }}</p>
        </div>
        <div>
          <p :class="$style.subtitle">Расчетный счет</p>
          <p :class="$style.info">{{ paymentAccount }}</p>
        </div>
        <div :class="$style.bankName">
          <p :class="$style.subtitle">Наименование банка</p>
          <p :class="$style.info">{{ bankName }}</p>
        </div>
        <div>
          <p :class="$style.subtitle">Кор. счет</p>
          <p :class="$style.info">{{ corAccount }}</p>
        </div>
        <div :class="$style.bic">
          <p :class="$style.subtitle">БИК</p>
          <p :class="$style.info">{{ bik }}</p>
        </div>
        <div>
          <p :class="$style.subtitle">Телефон</p>
          <p :class="$style.info">{{ phone }}</p>
        </div>
        <div :class="$style.email">
          <p :class="$style.subtitle">Email</p>
          <p :class="$style.info">{{ email }}</p>
        </div>
      </div>
    </div>

    <div v-if="!selectTypes.length" :class="$style.footer">
      <div v-if="!status" :class="$style.status">
        <div :class="$style.redMarker"></div>
        <p>на модерации</p>
      </div>
      <div v-else :class="$style.status">
        <div :class="$style.greenMarker"></div>
        <p>проверен</p>
      </div>
    </div>
  </div>
</template>

<script>
import Select from '@/components/atoms/Select.vue'

export default {
  components: {
    Select,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    legalAddress: {
      type: String,
      default: '',
    },
    unp: {
      type: String,
      default: '',
    },
    ogrn: {
      type: String,
      default: '',
    },
    kpp: {
      type: String,
      default: '',
    },
    paymentAccount: {
      type: String,
      default: '',
    },
    bankName: {
      type: String,
      default: '',
    },
    corAccount: {
      type: String,
      default: '',
    },
    bik: {
      type: String,
      default: '',
    },
    phone: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    status: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: String,
      default: null,
    },
    selectTypes: {
      type: Array,
      default: () => [],
    },
    selectError: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/breakpoints.scss';

.container {
  @include card;
  padding: 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  @include tablet {
    padding: 0;
  }
  .main {
    flex: 1 1 auto;
    padding: 3rem 3rem 2.69rem;
    .title {
      @include H200;
      margin-bottom: 1.5rem;
    }
    .select {
      max-width: 39.5rem;
      margin-bottom: 1.5rem;
      @include tablet {
        max-width: none;
      }
    }
    .name {
      @include H200;
      margin-bottom: 1.5rem;
    }
    .card {
      background-color: $white;
      color: $gray;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 1.5rem;
      grid-row-gap: 1.5rem;
      @include tablet {
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
      }
      .subtitle {
        font-size: 0.625rem;
        line-height: 0.875rem;
        margin-bottom: 0.25rem;
      }
      .address {
        grid-column-start: 1;
        grid-column-end: 4;
        @include tablet {
          grid-column-start: 1;
          grid-column-end: 2;
        }
      }
      .bankName,
      .bic,
      .email {
        grid-column-start: 2;
        grid-column-end: 4;
        @include tablet {
          grid-column-start: 1;
          grid-column-end: 2;
        }
      }
    }
  }
  .footer {
    @include rounded;
    @include baseShadow;
    background-color: $main-bg;
    padding: 0.75rem 3rem;
    display: flex;
    justify-content: space-between;
    color: $gray;
    @include mobile {
      flex-direction: column;
      padding: 0.75rem 1.5rem;
    }
    .status {
      display: flex;
      .redMarker {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background-color: $warning;
        margin: 0.5rem 0.5rem 0 0;
        @include mobile {
          margin-bottom: 2rem;
        }
      }
      .greenMarker {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background-color: $complite;
        margin: 0.5rem 0.5rem 0 0;
      }
    }
    .buttons {
      display: flex;
      @include mobile {
        justify-content: space-between;
      }
      a {
        @include grayLink;
        display: flex;
        align-items: center;
        &:first-child {
          margin-right: 1.5rem;
        }
        &:disabled,
        &.disabled {
          opacity: 0.4;
          pointer-events: none;
        }
        @include mobile {
          font-size: 0.9rem;
        }
      }
      .icon {
        margin-right: 0.5rem;
      }
    }
  }
}
</style>
