<template>
  <div :class="$style.container">
    <div :class="$style.card">
      <h2 :class="$style.title">{{ title }}</h2>
      <div>
        <p :class="$style.subtitle">Адрес доставки</p>
        <p :class="$style.info">{{ address }}</p>
      </div>
      <div>
        <p :class="$style.subtitle">Комментарии курьеру</p>
        <p :class="$style.info">{{ comment }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    address: {
      type: String,
      default: ''
    },
    comment: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/fonts.scss';
@import '@/assets/styles/breakpoints.scss';

.container {
  @include card;
  min-height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  @include tablet {
    padding: 0;
  }
  .card {
    flex: 1 1 auto;
    padding: 3rem;
    & > div:not(:last-child) {
      padding-bottom: 1.5rem;
    }
    @include tablet {
      padding: 1rem;
    }
    .title {
      @include H200;
      padding-bottom: 1.5rem;
    }
    .subtitle {
      font-size: 0.625rem;
      line-height: 0.875rem;
      margin-bottom: 0.25rem;
    }
  }
  .footer {
    @include rounded;
    @include baseShadow;
    background-color: $main-bg;
    padding: 0.81rem 3rem;
    display: flex;
    justify-content: space-between;
    @include tablet {
      padding: 0.8rem 1rem;
    }
    a {
      @include grayLink;
      display: flex;
      align-items: center;
      &:first-child {
        margin-right: 1.5rem;
      }
      @include mobile {
        font-size: 0.9rem;
      }
    }
    .icon {
      margin-right: 0.75rem;
    }
  }
}
</style>
